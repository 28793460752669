/* ------------------------------------------ */
/* Variables
/* ------------------------------------------ */

$background-color: RGB(36,35,41);
$red-color: RGB(225,10,60);
$sky-blue: RGB(39,169,235);
$battery-green: RGB(122,209,6);

$svg-width: 600px;
$svg-height: 82px;

html {
  height: 100%;
}

#main {
  height: 100%;
}

body {
  background: rgb(36, 35, 42);
  color: #EEEEEE;
  overflow: hidden;
  text-rendering: optimizeLegibility;
}

.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

h1.title {
  text-align: center;
  text-transform: uppercase;
}

h3.byline {
  text-align: center;
  font-size: 1.4em;
  opacity: 0.9;
  white-space: nowrap;
  letter-spacing: 0.05em;
  line-height: 1.5em;
  margin-top: 10px;
  text-transform: uppercase;
}

h4 {
  font-size: 1em;

  &.byline {
    text-align: center;
    font-size: 1.1em;
    opacity: 0.9;
    white-space: nowrap;
    letter-spacing: 0.05em;
    margin-top: 0px;
    text-transform: uppercase;
  }
}

#rotation-lock {
  visibility: hidden;
  background: rgba(0,0,0,0.85);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;

  .rotation-lock-svg {
    fill: $red-color;
    $lock-svg-size: 140px;
    width: $lock-svg-size;
    height: $lock-svg-size;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -($lock-svg-size/2);
    margin-left: -($lock-svg-size/2);
  }
}

.hero-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // When the svg first loads as an image and we haven't converted it yet, hide it.
  img.blackbox-svg {
    opacity: 0;
  }

  .blackbox-svg {
    width: $svg-width;
    height: $svg-height;
    margin-top: -200px; // up from center

    &.animated {
      transition-duration: 1.2s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      margin-top: -330px;
      &.animate {
        margin-top: -265px;
      }
    }
  }

  .input__label--foldie {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    margin-top: 245px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    white-space: nowrap;
    font-size: 82.25%;
  }
}

.positive, .negative, .clipper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.clipper {
  z-index: -100;
}

.maxSize {
  width: 100%;
  height: 100%;
}

#clipped {
  -webkit-clip-path: url(#mask2);
  clip-path: url(#mask2);
}

.positive {
  background: $background-color;
  p, a {
    color: $red-color;
  }

  svg.blackbox-svg.animated {
    fill: $background-color;

    &.animate {
      fill: $red-color;
    }
  }

  .byline.animated {
    color: $background-color;
    &.animate {
      transition: color, 2.4s, ease-out, 1.1s;
      color: $red-color;
    }
  }

  .input__label--foldie.animated-with-delay-1 {
    color: $background-color;
    &.animate {
      transition: color, 0.6s, ease-out;
      color: $red-color;
    }
  }
}

.negative {
  position: absolute;
  top: 0;
  background: $red-color;
  z-index: -100;

  p, a {
    color: $background-color;
  }

  svg.blackbox-svg {
    fill: $red-color;

    &.animate {
      fill: $background-color;
    }
  }

  .tick-mark:before {
    background-color: $background-color;
  }

  .byline.animated {
    color: $red-color;
    &.animate {
      transition: color, 2.4s, ease-out, 1.1s;
      color: $background-color;
    }
  }

  .input__label--foldie.animated-with-delay-1 {
    color: $red-color;
    &.animate {
      transition: color, 0.6s, ease-out;
      color: $background-color;
    }
  }
}

.tick-mark {
  $width: 54px;
  $height: 2px;
  $top: 50%;

  &:before {
    content: "";
    position: absolute;
    top: $top;
    margin-top: -($height/2);

    width: $width;
    height: $height;

    background-color: $red-color;
  }

  &.tick-mark-right:before {
    right: 60px;
  }

  &.tick-mark-left:before {
    left: 60px;
  }
}




.center-floating-container {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 100;

  // margin-top: 395px; // (260px + footer_height);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.video-button {

  display: block;
  &:hover { opacity: 0.9; }
  &:active { opacity: 0.8; }

  &.animated {
    opacity: 0;
    &.animate {
      opacity: 1;
      transition: opacity 1.2s ease-out 0.6s;
    }
  }

  .video-button-thumbnail {
    height: 180px;
  }
}

.btn-app-store {
  opacity: 0;
  margin-top: 8px;

  & > svg {
    width: 200px;
    height: 60px;
  }

  &.animate {
    opacity: 1;
    transition: opacity, 0.8s, ease-out, 0s;
  }
}

.footer {
  $footer-padding: 1.80em;

  position: absolute;
  height: 135px;
  padding: $footer-padding;
  top: initial;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: center;

  &.fake-pass-through {
    opacity: 0;
  }

  .social-icons {
    $social-button-size: 48px;

    .btn-social-icon {
      width: $social-button-size;
      height: $social-button-size;
      padding: 0;
      border: 2px solid;
      border-radius: 0;

      .fa-solid {
        font-size: 1.6em;
        line-height: $social-button-size - 3px;
        vertical-align: middle;
      }

      &:not(:first-child) {
        border-left: none;
      }
    }
  }

  .text-links {
    a {
      opacity: 0.75;
      text-transform: uppercase;
      font-size: 0.9em;
      letter-spacing: 0.25em;
      z-index: 300;

      &:hover {
        opacity: 1;
        text-decoration: none;
      }

      margin-right: 26px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}


@mixin footer-style($primary-color, $secondary-color) {
  .btn-social-icon {
    border-color: $primary-color;
    color: $primary-color;

    &:hover {
      background-color: $primary-color;
      color: $secondary-color;
    }

    &:first-child {
      border-right-color: $primary-color;
    }

    &:not(:first-child) {
      border-left-color: $primary-color;
    }
  }

  .text-links a {
    color: $primary-color;
  }
}

.footer.transition {
  .btn-social-icon {
    transition: color 0.75s ease-in, border-color 0.75s ease-in;
  }

  .text-links a {
    transition: color 0.75s ease-in, border-color 0.75s ease-in;
  }
}

.positive .footer {
  // Only the footer on top's hover events will work and I don't want to do anything complex right now to fix it

  @include footer-style($background-color, $red-color);

  &.color-in {
    @include footer-style($red-color, $background-color);
  }
}

.negative .footer {
  @include footer-style($red-color, $background-color);

  &.color-in {
    @include footer-style($background-color, $red-color);
  }
}


/* ------------------------------------------ */
/* Circle Arrows
/* ------------------------------------------ */
// input.button {
//   display: inline-block;
//   vertical-align: middle;

//   &:before {
//     content: '';
//     display: inline-block;
//     width: 4em;
//     height: 4em;
//     border: 0.5em solid #333;
//     border-radius: 50%;
//     margin-left: 1.5em;
//   }

//   &:after {
//     content: '';
//     display: inline-block;
//     margin-top: 1.05em;
//     margin-left: -0.6em;
//     width: 1.4em;
//     height: 1.4em;
//     border-top: 0.5em solid #333;
//     border-right: 0.5em solid #333;
//     -moz-transform: rotate(45deg);
//     -webkit-transform: rotate(45deg);
//     transform: rotate(45deg);
//   }
// }

.circle-arrow-button {
  $circle-size: 24px;
  $circle-arrow-size: 9px;
  $circle-arrow-stroke-width: 2px;
  $circle-arrow-color: $sky-blue;

  display: inline-block;
  vertical-align: middle;

  a {
    display: inline-block;
    border-radius: 50%;
  }

  // a:hover .right{
  //   border: $circle-arrow-stroke-width solid #e74c3c;
  // }

  .right {
    display: inline-block;
    width: $circle-size;
    height: $circle-size;
    border: $circle-arrow-stroke-width solid $circle-arrow-color;
    border-radius: 50%;
  }

  .right:after {
    content: '';
    display: inline-block;
    margin-bottom: 1px;
    margin-right: 1px;
    width: $circle-arrow-size;
    height: $circle-arrow-size;
    border-top: $circle-arrow-stroke-width solid $circle-arrow-color;
    border-right: $circle-arrow-stroke-width solid $circle-arrow-color;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}


/* ------------------------------------------ */
/* Animations
/* ------------------------------------------ */

// Social Button "Color" In
// .color-in-positive {
//   @include animation-duration(400ms);
//   @include animation-fill-mode(both);
//   @include animation-timing-function(ease-in);
//   @include animation-name(color-in-positive);
// }

// @include keyframes(shake) {
//     // 0%, 100% {transform: translateX(0);}
//     // 10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
//     // 20%, 40%, 60%, 80% {transform: translateX(10px);}

//     0% {
//       @include transform(translateX(0));
//     }

//     100% {
//       @include transform(translateX(-6px) rotateY(-5deg));
//     }
// }


// Shake Animation

.shake {
  animation-duration: 400ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-name: shake;
}

.input__label--foldie.shake::before {
  animation-duration: 400ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-name: shake;
}

@keyframes shake {
    // 0%, 100% {transform: translateX(0);}
    // 10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
    // 20%, 40%, 60%, 80% {transform: translateX(10px);}

    0% {
      transform: translateX(0);
    }

    12.5% {
      transform: translateX(-6px) rotateY(-5deg);
    }

    37.5% {
      transform: translateX(5px) rotateY(4deg);
    }

    62.5% {
      transform: translateX(-3px) rotateY(-2deg);
    }

    87.5% {
      transform: translateX(2px) rotateY(1deg);
    }

    100% {
      transform: translateX(0);
    }
}

/* ------------------------------------------ */
/* Media Queries
/* ------------------------------------------ */

@media prefers-reduced-motion {
  
}

@media only screen
  and (min-width: 0px)
  and (max-width: 668px) {

  $sm-svg-width: 0.46 * $svg-width;
  $sm-svg-height: (82/600) * $sm-svg-width;

  .positive svg.blackbox-svg,
  .negative svg.blackbox-svg {
    width: $sm-svg-width;
    height: $sm-svg-height;
  }

  h3.byline {
    font-size: 0.8em;
    margin-top: 1em;
  }

  h4.byline {
    font-size: 0.75em;
  }

  .hero-container {
    .blackbox-svg {
      &.animated {
        transition: 1.5s, ease-out;
        margin-top: -65px;
        &.animate {
          margin-top: -260px;
        }
      }
    }

    .input__label--foldie {
      margin-top: 110px;
    }
  }

  .center-floating-container {
    margin-top: -70px;

    .video-button-thumbnail {
      height: 90px;
    }
  }
}

@media only screen
  and (min-width: 0px)
  and (max-width: 566px) {
  .footer {
    align-content: space-between;
    height: 110px;
    padding: 1em;

    & > div {
      width: 100%;
      text-align: center;
    }
  }
}

@media only screen
  and (min-width: 0px)
  and (max-width: 840px) {
    .tick-mark-left {
      display: none;
    }
    .tick-mark-right {
      display: none;
    }
}

@media only screen
  and (min-width: 0px)
  and (max-width: 400px)
  and (min-height: 0px)
  and (max-height: 420px) {
  .footer .social-icons {
    display: none;
  }
}
